import { useContext, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  Link,
  Divider,
} from "@mui/material";
import { AuthContext } from "context/AuthContext";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { handleLogin } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await handleLogin(username, password);
      setIsSuccess(true);
      navigate("/");
    } catch (error) {
      setErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card sx={{ width: "300px" }}>
        <CardContent>
          <Typography
            variant="h4"
            sx={{ textAlign: "center", marginBottom: 2 }}
          >
            Login
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Username"
              value={username}
              onChange={(event) => setUsername(event.target.value)}
              fullWidth
              margin="normal"
              disabled={isLoading || isSuccess}
            />
            <TextField
              label="Password"
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              fullWidth
              margin="normal"
              disabled={isLoading || isSuccess}
            />
            <Divider sx={{ m: 1 }} />
            <Button
              variant="contained"
              type="submit"
              fullWidth
              disabled={isLoading || isSuccess}
            >
              {isLoading ? "Logging in..." : "Login"}
            </Button>
            {errorMessage && (
              <Box sx={{ color: "red", marginTop: 2 }}>
                <Typography>{errorMessage}</Typography>
              </Box>
            )}
          </form>
          <Box sx={{ marginTop: 2 }}>
            <Typography variant="body2" align="center">
              Don't have an account?{" "}
              <Link href="/register" underline="always">
                Register
              </Link>
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Login;
