import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  Frame,
  Home,
  Stock,
  ErrorReport,
  ReportsQuery,
  ErrorReportMod,
  InventoryReport,
  InventoryRecordsQuery,
} from "pages/home";
import Login from "pages/Login";
import Register from "pages/Register";
import Test from "pages/Test";

const RootRoutes = () => {
  return (
    <RouterProvider
      router={createBrowserRouter([
        {
          path: "/",
          element: <Frame />,
          children: [
            { path: "/", element: <Home /> },
            {
              path: "/defects",
              element: <ErrorReport />,
            },
            {
              path: "/defects/mod",
              element: <ErrorReportMod />,
            },
            {
              path: "/defects/mod/:id",
              element: <ErrorReportMod />,
            },
            {
              path: "/stock",
              element: <Stock />,
            },
            {
              path: "/query",
              element: <ReportsQuery />,
            },
            {
              path: "/inventory",
              element: <InventoryRecordsQuery />,
            },
            {
              path: "/inventory/mod",
              element: <InventoryReport />,
            },
            {
              path: "/inventory/mod/:id",
              element: <InventoryReport />,
            },
          ],
        },
        {
          path: "/login",
          element: <Login />,
        },
        {
          path: "/register",
          element: <Register />,
        },
        {
          path: "/test",
          element: <Test />,
        },
      ])}
    />
  );
};

export default RootRoutes;
