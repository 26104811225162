import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Outlet, Link } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CropFreeIcon from "@mui/icons-material/CropFree";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DangerousIcon from "@mui/icons-material/Dangerous";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ReportsQuery from "./ReportsQuery";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import { useAuthContext } from "context/AuthContext";
import InventoryIcon from "@mui/icons-material/Inventory";

const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const { user, handleLogout, handleSession } = useAuthContext();

  React.useEffect(() => {
    handleSession();
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const DrawerListItem = (props) => {
    const { to, text, icon } = props;
    return (
      <ListItem component={Link} to={to} disablePadding>
        <ListItemButton onClick={handleDrawerToggle}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={text} sx={{ color: "text.primary" }} />
        </ListItemButton>
      </ListItem>
    );
  };

  const drawer = (
    <div>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2, display: { sm: "none" } }}
          onClick={handleDrawerToggle}
        >
          <MenuIcon sx={{ fontSize: 28 }} />
        </IconButton>
        {user ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
            }}
          >
            <Typography sx={{ color: "text.secondary", fontSize: 12 }}>
              欢迎
            </Typography>
            <Typography sx={{ fontSize: 18 }}>{user.username}</Typography>
          </Box>
        ) : (
          <Typography component={Link} to="/login" sx={{ flexGrow: 1 }}>
            请登录
          </Typography>
        )}
        {user ? (
          <IconButton
            color="inherit"
            aria-label="logout"
            onClick={handleLogout}
          >
            <ExitToAppIcon sx={{ fontSize: 28 }} />
          </IconButton>
        ) : (
          <IconButton
            component={Link}
            to="/login"
            color="inherit"
            aria-label="login"
          >
            <AccountCircleIcon sx={{ fontSize: 28 }} />
          </IconButton>
        )}
      </Toolbar>
      <Divider />
      <List>
        <DrawerListItem to="/" text="主页" icon={<HomeIcon />} />
      </List>
      <Divider />
      <List>
        <DrawerListItem to="/query" text="不良信息" icon={<DangerousIcon />} />
        <DrawerListItem
          to="/inventory"
          text="出入库信息"
          icon={<InventoryIcon />}
        />
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            合肥美金汽车配件有限公司
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          {/* <IconButton color="inherit" aria-label="open drawer" edge="start">
            <CropFreeIcon />
          </IconButton> */}
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          mt: 7,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
