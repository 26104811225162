export async function postImage(blobImage) {
  const formData = new FormData();
  formData.append("image", blobImage, "image.jpg");

  const response = await fetch("/api/image", {
    method: "POST",
    body: formData,
  });

  if (!response.ok) {
    throw new Error("Failed to upload image");
  }

  const data = await response.json();
  return data.srcId;
}
