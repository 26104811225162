import { createContext, useState, useContext } from "react";

export const AuthContext = createContext();

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const handleLogin = async (username, password) => {
    try {
      const response = await fetch("/api/user/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });
      if (response.ok) {
        const user = await response.json();
        setUser(user);
        setIsAuthenticated(true);
        if (user.username == "郑润宇" || user.username == "郑永哲")
          setIsAdmin(true);
      } else {
        try {
          const error = await response.json();
          throw error.message;
        } catch (err) {
          if (err instanceof SyntaxError) {
            throw "无法连接服务器";
          }
          throw err;
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const handleLogout = async () => {
    setUser(null);
    setIsAuthenticated(false);

    try {
      // 向 /api/user/logout 发送 POST 请求
      const response = await fetch("/api/user/logout", {
        method: "POST",
        credentials: "include", // 发送 cookies
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}), // 发送空对象作为请求体
      });

      if (!response.ok) {
        throw new Error("Logout failed");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSession = async () => {
    try {
      const response = await fetch("/api/user/session");
      if (response.ok) {
        const user = await response.json();
        if (user) {
          setUser(user);
          setIsAuthenticated(true);
          if (user.username == "郑润宇" || user.username == "郑永哲")
            setIsAdmin(true);
        }
      } else {
        throw new Error("Failed to check session.");
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        isAdmin,
        handleLogin,
        handleLogout,
        handleSession,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
