import { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";

import { LoadingButton } from "@mui/lab";
import ImageStack from "./ImageStack";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import { convertImage } from "js/imageConverter";
import { postImage } from "js/api";

const ImageUploader = (props) => {
  const { value, onChange } = props;
  const [images, setImages] = useState(value);

  async function dataURLtoJPG(dataUrl) {
    const blobImage = await convertImage(dataUrl);
    const srcId = await postImage(blobImage);
    return srcId;
  }

  const onUploadImage = (imageList) => {
    imageList.forEach((image) => {
      dataURLtoJPG(image.data_url).then((uploadedImage) => {
        setImages((preImages) => {
          const newImages = [...preImages, uploadedImage];
          return newImages;
        });
      });
    });
  };

  const handleDeleteImage = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    setImages(newImages);
  };

  useEffect(() => {
    onChange(images);
  }, [images]);

  return (
    <ImageUploading multiple onChange={onUploadImage} dataURLKey="data_url">
      {({ onImageUpload }) => (
        <>
          <UploadButton onClick={onImageUpload} />
          <ImageStack
            images={images}
            onClick={handleDeleteImage}
            centerIcon={<DeleteIcon />}
          />
        </>
      )}
    </ImageUploading>
  );
};

const UploadButton = (props) => {
  const { onClick } = props;
  return (
    <LoadingButton
      sx={{ mb: 2 }}
      variant="outlined"
      color="primary"
      onClick={onClick}
    >
      上传图片
    </LoadingButton>
  );
};

const DeleteIcon = () => {
  return (
    <HighlightOffIcon
      style={{
        position: "absolute",
        zIndex: "1",
        color: "white",
        backgroundColor: "black",
        borderRadius: 100,
      }}
    />
  );
};

export default ImageUploader;
