import { ImageUploader, ImageStack } from "components/images";
import ImageModal from "components/images/ImageModal";
import { useEffect, useState } from "react";

const Test = () => {
  const [images, setImages] = useState([]);

  const [imageModal, setImageModal] = useState(false);
  const [previewId, setPreviewId] = useState();

  useEffect(() => {
    console.log(images);
  }, [images]);

  const handlePreviewImage = (index, srcId) => {
    setPreviewId(srcId);
    setImageModal(true);
  };

  const handleClose = () => {
    setImageModal(false);
  };

  return (
    <div>
      <ImageUploader
        value={images}
        onChange={(srcList) => setImages(srcList)}
      />
      <ImageStack images={images} onClick={handlePreviewImage} />
      <ImageModal open={imageModal} onClose={handleClose} srcId={previewId} />
    </div>
  );
};

export default Test;
