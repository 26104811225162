import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import { useAuthContext } from "context/AuthContext";

import BuildIcon from "@mui/icons-material/Build";
import { useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import { ImageStack, ImageModal } from "components/images";

const ReportsQuery = () => {
  const [reportsData, setReportsData] = useState([]);
  const { user } = useAuthContext();

  const [imageModal, setImageModal] = useState(false);
  const [previewId, setPreviewId] = useState();

  const handlePreviewImage = (index, srcId) => {
    setPreviewId(srcId);
    setImageModal(true);
  };

  const handleClose = () => {
    setImageModal(false);
  };

  const navigate = useNavigate();

  function formatDate(date) {
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function formatDateFromString(str) {
    return formatDate(new Date(str));
  }

  useEffect(() => {
    async function fetchData() {
      if (!user) return;
      try {
        const response = await fetch(
          `/api/defects?username=${user.username}&date=now`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setReportsData(data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, [user]);

  return (
    <Paper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <IconButton onClick={() => navigate("/defects/mod")}>
                  <AddIcon />
                </IconButton>
              </TableCell>
              <TableCell>时间</TableCell>
              <TableCell>姓名</TableCell>
              <TableCell>不良人员</TableCell>
              <TableCell>客户</TableCell>
              <TableCell>图号</TableCell>
              <TableCell>工序</TableCell>
              <TableCell>机器型号</TableCell>
              <TableCell>不良数量</TableCell>
              <TableCell>总数</TableCell>
              <TableCell>备注</TableCell>
              <TableCell>图片</TableCell>
              <TableCell>已更新</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportsData.map((report) => (
              <TableRow key={report.id}>
                <TableCell
                  onClick={() =>
                    navigate(
                      `/defects/mod/${report.id}?${new URLSearchParams({
                        report: JSON.stringify(report),
                      }).toString()}`
                    )
                  }
                >
                  <IconButton>
                    <BuildIcon />
                  </IconButton>
                </TableCell>
                <TableCell>{formatDateFromString(report.prod_date)}</TableCell>
                <TableCell>{report.log_name}</TableCell>
                <TableCell>{report.defects_worker}</TableCell>
                <TableCell>{report.customer}</TableCell>
                <TableCell>{report.draw_id}</TableCell>
                <TableCell>{report.process}</TableCell>
                <TableCell>{report.machine}</TableCell>
                <TableCell>{report.defects}</TableCell>
                <TableCell>{report.total}</TableCell>
                <TableCell>{report.comment}</TableCell>
                <TableCell>
                  <ImageStack
                    images={JSON.parse(report.images)}
                    onClick={handlePreviewImage}
                  />
                </TableCell>
                <TableCell>{report.is_updated}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ImageModal open={imageModal} onClose={handleClose} srcId={previewId} />
    </Paper>
  );
};

export default ReportsQuery;
