import { useState } from "react";
import { ImageList, ImageListItem } from "@mui/material";
import ImageUploading from "react-images-uploading";
import { Box, Button, Card, CardContent, Stack } from "@mui/material";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useAuthContext } from "context/AuthContext";
const DeleteableImage = ({ src, onClick }) => {
  return (
    <Card
      onClick={onClick}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 100,
        height: 100,
        border: 1,
      }}
    >
      <HighlightOffIcon
        style={{
          position: "absolute",
          zIndex: "1",
          color: "white",
          backgroundColor: "black",
          borderRadius: 100,
        }}
      />
      <img src={src} alt="" width="90" style={{ position: "relative" }} />
    </Card>
  );
};

const UploadPreviewImage = (props) => {
  const { onChange } = props;
  const [images, setImages] = useState([]);
  const { user } = useAuthContext();

  const onUploadImage = (imageList) => {
    console.log(imageList);
    // setImages(imageList);
    // onChange(imageList);
  };

  const handleDeleteImage = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    setImages(newImages);
    onChange(newImages);
  };

  if (user && user.username != "郑润宇" && user.username != "郑永哲") return;
  return (
    <ImageUploading
      multiple
      value={images}
      onChange={onUploadImage}
      dataURLKey="data_url"
    >
      {({ imageList, onImageUpload, onImageRemove }) => (
        <div className="upload__image-wrapper">
          <Button
            sx={{ mb: 2 }}
            variant="contained"
            color="primary"
            onClick={onImageUpload}
          >
            上传图片
          </Button>
          <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap">
            {imageList.map((image, index) => (
              <DeleteableImage
                key={index}
                src={image["data_url"]}
                onClick={() => handleDeleteImage(index)}
              />
            ))}
          </Stack>
        </div>
      )}
    </ImageUploading>
  );
};

export default UploadPreviewImage;
