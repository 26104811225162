import { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  Link,
  Divider,
} from "@mui/material";

const Register = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setErrorMessage("两次输入的密码不一致");
      return;
    }

    if (password.length < 6) {
      setErrorMessage("密码长度不能少于6位");
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch("/api/user", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });
      if (response.ok) {
        setIsSuccess(true);
        setErrorMessage(null);
        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);
      } else {
        throw new Error("用户创建失败，请联系管理员");
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card sx={{ width: "300px" }}>
        <CardContent>
          <Typography
            variant="h4"
            sx={{ textAlign: "center", marginBottom: 2 }}
          >
            用户注册
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="用户名"
              value={username}
              onChange={(event) => setUsername(event.target.value)}
              fullWidth
              margin="normal"
              disabled={isLoading || isSuccess}
            />
            <TextField
              label="密码"
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              fullWidth
              margin="normal"
              disabled={isLoading || isSuccess}
            />
            <TextField
              label="确认密码"
              type="password"
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
              fullWidth
              margin="normal"
              disabled={isLoading || isSuccess}
            />
            <Divider sx={{ m: 1 }} />
            <Button
              variant="contained"
              type="submit"
              fullWidth
              disabled={isLoading || isSuccess}
            >
              注册
            </Button>
            {errorMessage && (
              <Box sx={{ color: "red", marginTop: 2 }}>
                <Typography>{errorMessage}</Typography>
              </Box>
            )}
            {isSuccess && !errorMessage && (
              <Box sx={{ color: "green", marginTop: 2 }}>
                <Typography>用户创建成功，请等待跳转</Typography>
              </Box>
            )}
          </form>
          <Box sx={{ marginTop: 2 }}>
            <Typography variant="body2" align="center">
              已经拥有账号？{" "}
              <Link href="/login" underline="always">
                登录
              </Link>
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Register;
