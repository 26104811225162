import { CircularProgress, ImageList, ImageListItem } from "@mui/material";
import { Box, Button, Card, CardContent, Stack } from "@mui/material";

const ImageStack = (props) => {
  const { images, onClick, centerIcon } = props;
  return (
    <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap">
      {images?.map((srcId, index) => (
        <ActiveImage
          key={index}
          srcId={srcId}
          onClick={() => onClick?.(index, srcId)}
          centerIcon={centerIcon}
        />
      ))}
    </Stack>
  );
};

const ActiveImage = (props) => {
  const { srcId, onClick, centerIcon } = props;
  const src = `/api/image/${srcId}.jpg`;

  return (
    <Card
      onClick={onClick}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 100,
        height: 100,
        border: 1,
      }}
    >
      {centerIcon}
      <img src={src} alt="" width="90" style={{ position: "relative" }} />
    </Card>
  );
};

export default ImageStack;
