import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  Stack,
  IconButton,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import { useAuthContext } from "context/AuthContext";

const Announcement = (props) => {
  const { id, title, date, children, onDelete, deleteable } = props;
  const announcementDate = new Date(date).toLocaleDateString();

  const handleDelete = () => {
    fetch(`/api/announcement/${id}`, {
      method: "DELETE",
    })
      .then(() => {
        onDelete();
      })
      .catch((error) => {
        console.error("Error deleting announcement:", error);
      });
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h5" component="h2">
                • {title}
              </Typography>
              {deleteable && (
                <IconButton onClick={handleDelete}>
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" component="p" color="textSecondary">
              {announcementDate}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body2"
              component="p"
              sx={{ whiteSpace: "pre-line" }}
            >
              {children.split("\n").map((text, index) => (
                <React.Fragment key={index}>
                  {text}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
const AnnouncementInput = (props) => {
  const { onCreate } = props;
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const handleAddAnnouncement = () => {
    fetch("/api/announcement", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ title, message }),
    })
      .then((response) => response.json())
      .then((data) => {
        setTitle("");
        setMessage("");
        onCreate();
        window.scrollTo(0, 0);
      })
      .catch((error) => console.error(error));
  };

  const handleKeyDown = (event) => {
    if (event.ctrlKey && event.key === "Enter") {
      handleAddAnnouncement();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  return (
    <Card sx={{ mt: 4 }}>
      <CardContent>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography variant="h5" component="h2">
              Add Announcement
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              label="Title"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              label="Message"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
              multiline
              rows={4}
              fullWidth
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddAnnouncement}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const Home = () => {
  const [announcements, setAnnouncements] = useState([]);
  const { isAdmin } = useAuthContext();

  const handleDelete = (id) => {
    setAnnouncements(announcements.filter((a) => a.id !== id));
  };

  const updateAnnouncement = () => {
    fetch("/api/announcement")
      .then((response) => response.json())
      .then((data) => setAnnouncements(data))
      .catch((error) => console.error(error));
  };

  const handleCreate = () => {
    updateAnnouncement();
  };

  useEffect(() => {
    updateAnnouncement();
  }, []);

  return (
    <>
      <Stack spacing={4}>
        {announcements.map((announcement) => (
          <Grid item key={announcement.id}>
            <Announcement
              id={announcement.id}
              title={announcement.title}
              date={announcement.log_date}
              onDelete={() => handleDelete(announcement.id)}
              deleteable={isAdmin}
            >
              {announcement.message}
            </Announcement>
          </Grid>
        ))}
      </Stack>
      {isAdmin && <AnnouncementInput onCreate={handleCreate} />}
    </>
  );
};

export default Home;
