import React, { useEffect, useState, useRef } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  Snackbar,
  Alert,
  Box,
} from "@mui/material";
import { useAuthContext } from "context/AuthContext";
import moment from "moment";

import QrScannerModal from "components/QrScannerModal";
import { ImageUploader } from "components/images";

import { useSearchParams, useParams, useNavigate } from "react-router-dom";

import { DateInput } from "components/inputs";

function InventoryReports() {
  const { id } = useParams();
  let [searchParams] = useSearchParams();
  const record = JSON.parse(searchParams.get("record"));

  const { user } = useAuthContext();
  const [date, setDate] = useState(
    moment(record?.inventory_date ? record.inventory_date : new Date()).format(
      "YYYY-MM-DD"
    )
  );
  const [name, setName] = useState("");

  const [partNumber, setPartNumber] = useState(
    record?.part_number ? record.part_number : ""
  );
  const [process, setProcess] = useState(record?.process ? record.process : "");
  const [machineModel, setMachineModel] = useState(
    record?.machine ? record.machine : ""
  );

  const [stockQuantity, setStockQuantity] = useState(
    record?.incoming ? record.incoming : ""
  );
  const [defectiveQuantity, setDefectiveQuantity] = useState(
    record?.defective ? record.defective : ""
  );

  const [halting, setHalting] = useState(record?.on_hold ? record.on_hold : "");
  const [workHour, setWorkHour] = useState(
    record?.record_time ? record.record_time : ""
  );

  const [image, setImage] = useState(
    record?.image_path ? JSON.parse(record.image_path) : []
  );
  const [notes, setNotes] = useState(record?.comment ? record.comment : "");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [isEnterSubmit, setIsEnterSubmit] = useState(false);

  const navigate = useNavigate();

  const formRef = useRef(null);

  useEffect(() => {
    setName(user ? user.username : "");
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isEnterSubmit) return;

    const inventoryReport = {
      inventory_date: date,
      name: name,
      part_number: partNumber,
      process: process,
      machine: machineModel,
      defective: defectiveQuantity ? defectiveQuantity : null,
      on_hold: halting ? halting : null,
      incoming: stockQuantity ? stockQuantity : null,
      record_time: workHour ? workHour : null,
      image_path: JSON.stringify(image),
      comment: notes,
    };

    try {
      const response = await fetch(`/api/inventory${id ? "/" + id : ""}`, {
        method: id ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(inventoryReport),
      });

      if (!response.ok) {
        throw new Error("Failed to upload defects report");
      }
      setOpenSnackbar(true);
      setPartNumber("");
      setProcess("");
      setMachineModel("");
      setDefectiveQuantity("");
      setStockQuantity("");
      setWorkHour("");
      setHalting("");
      setImage([]);
      setNotes("");
      navigate("/inventory");
    } catch (error) {
      console.error(error);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      if (!formRef.current.reportValidity()) return;
      if (e.ctrlKey) {
        handleSubmit(e);
      }
    }
  };

  const handleScan = (data) => {
    const params = /dn=(.*)/.exec(data)[1];

    setPartNumber(params);

    // const [drawId, process, machine, defects, total] = data.split(",");

    // setPartNumber(drawId);
    // setProcess(process);
    // setMachineModel(machine);
    // setDefectiveQuantity(defects);
    // setTotal(total);
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          提交不良报告成功
        </Alert>
      </Snackbar>
      <Typography variant="h4" gutterBottom>
        出入库系统 {id ? `修改 id:${id}` : ""}
      </Typography>
      <form ref={formRef} onKeyDown={handleKeyDown} onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <DateInput value={date} onChange={(e) => setDate(e)} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="name"
              label="姓名"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>{" "}
          <Grid item xs={12} sx={{ position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                height: 56,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1,
                right: 8,
              }}
            >
              <QrScannerModal onScan={handleScan} />
            </Box>
            <TextField
              required
              id="partNumber"
              label="图号"
              fullWidth
              value={partNumber}
              onChange={(e) => setPartNumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              required
              id="process"
              label="工序"
              fullWidth
              value={process}
              onChange={(e) => setProcess(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              id="machineModel"
              label="机器型号"
              fullWidth
              value={machineModel}
              onChange={(e) => setMachineModel(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              id="stockQuantity"
              label="入库"
              fullWidth
              value={stockQuantity}
              onChange={(e) => setStockQuantity(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              id="workHour"
              label="时间"
              fullWidth
              value={workHour}
              onChange={(e) => setWorkHour(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              id="defectiveQuantity"
              label="不良数量"
              type="number"
              fullWidth
              value={defectiveQuantity}
              onChange={(e) => setDefectiveQuantity(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              id="halting"
              label="待工"
              fullWidth
              value={halting}
              onChange={(e) => setHalting(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="notes"
              label="备注"
              fullWidth
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <ImageUploader
              value={image}
              onChange={(images) => setImage(images)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" fullWidth type="submit">
              提交
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}

export default InventoryReports;
