import moment from "moment";
import { Button, Box, Stack, TextField } from "@mui/material";

const DateInput = (props) => {
  const { value, onChange } = props;

  const handleClickYesterday = () => {
    var date = new Date();
    date.setDate(date.getDate() - 1);
    onChange(moment(date).format("YYYY-MM-DD"));
  };

  const handleClickToday = () => {
    var date = new Date();
    onChange(moment(date).format("YYYY-MM-DD"));
  };

  const isToday = new Date(value).getDate() == new Date().getDate();
  const isYesterday = new Date(value).getDate() == new Date().getDate() - 1;

  const mDate = moment(value);
  const weekday = [
    "星期日",
    "星期一",
    "星期二",
    "星期三",
    "星期四",
    "星期五",
    "星期六",
  ];
  const dateString = mDate.format("YYYY-MM-DD");
  const weekdayString = weekday[mDate.weekday()];
  const toYesDayString = isToday ? "今天" : isYesterday ? "昨天" : "";

  const dateWeekString = `${toYesDayString} ${dateString} ${weekdayString}`;

  const handleDateChange = (e) => {
    console.log(e.target.value);
  };

  const dateButtonComps =
    !isToday && !isYesterday ? (
      ""
    ) : (
      <>
        {/* <Button
          variant={`${isToday ? "text" : "contained"}`}
          onClick={handleClickYesterday}
        >
          昨天
        </Button>
        <Button
          variant={`${isToday ? "contained" : "text"}`}
          onClick={handleClickToday}
        >
          今天
        </Button> */}
      </>
    );

  return (
    <Stack sx={{ flexDirection: "row", gap: 1 }}>
      <Box sx={{ flexGrow: 1 }}>
        <TextField
          required
          id="date"
          label="日期"
          type="date"
          value={value}
          onChange={handleDateChange}
          fullWidth
        />
      </Box>
      {dateButtonComps}
    </Stack>
  );
};

export default DateInput;
