export async function convertImage(dataUrl) {
  const img = await loadImage(dataUrl);
  const blob = await imageToBlob(img);
  return blob;
}

function loadImage(dataUrl) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = () => reject(new Error("Failed to load image"));
    img.src = dataUrl;
  });
}

function imageToBlob(img) {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, img.width, img.height);
    canvas.toBlob((blob) => resolve(blob), "image/jpeg");
  });
}
