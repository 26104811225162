import { Modal, Box } from "@mui/material";
import { useState } from "react";
import SrcIdImage from "./SrcIdImage";

const ImageModal = (props) => {
  const { srcId, open, onClose } = props;

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <SrcIdImage srcId={srcId} sx={{ width: "100%" }} />
      </Box>
    </Modal>
  );
};

export default ImageModal;
