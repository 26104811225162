import React, { useEffect, useState, useRef } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  Snackbar,
  Alert,
  IconButton,
  Box,
} from "@mui/material";
import UploadPreviewImage from "components/UploadPreviewImage";
import { useAuthContext } from "context/AuthContext";
import moment from "moment";

import QrCodeIcon from "@mui/icons-material/QrCode";
import QrScannerModal from "components/QrScannerModal";
import { useNavigate } from "react-router-dom";

function ErrorReport() {
  const { user } = useAuthContext();
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [name, setName] = useState("");
  const [defectivePerson, setDefectivePerson] = useState("");
  const [customer, setCustomer] = useState("");
  const [partNumber, setPartNumber] = useState("");
  const [process, setProcess] = useState("");
  const [machineModel, setMachineModel] = useState("");
  const [defectiveQuantity, setDefectiveQuantity] = useState("");
  const [total, setTotal] = useState("");
  const [image, setImage] = useState([]);
  const [notes, setNotes] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openScanner, setOpenScanner] = useState(false);

  const navigate = useNavigate();

  const [isEnterSubmit, setIsEnterSubmit] = useState(false);

  const formRef = useRef(null);

  useEffect(() => {
    setName(user ? user.username : "");
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isEnterSubmit) return;

    const defectReport = {
      prod_date: date,
      log_name: name,
      defects_worker: defectivePerson,
      customer: customer,
      draw_id: partNumber,
      process: process,
      machine: machineModel,
      defects: defectiveQuantity,
      total: total,
      images: JSON.stringify(image),
      comment: notes,
    };

    try {
      const response = await fetch("/api/defects", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(defectReport),
      });
      if (!response.ok) {
        throw new Error("Failed to upload defects report");
      }
      setOpenSnackbar(true);
      setDefectivePerson("");
      setCustomer("");
      setPartNumber("");
      setProcess("");
      setMachineModel("");
      setDefectiveQuantity("");
      setTotal("");
      setImage([]);
      setNotes("");
      navigate("/query");
    } catch (error) {
      console.error(error);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      if (!formRef.current.reportValidity()) return;
      if (e.ctrlKey) {
        handleSubmit(e);
      }
    }
  };

  const handleScan = (data) => {
    const params = /dn=(.*)/.exec(data)[1];

    setPartNumber(params);

    // const [drawId, process, machine, defects, total] = data.split(",");

    // setPartNumber(drawId);
    // setProcess(process);
    // setMachineModel(machine);
    // setDefectiveQuantity(defects);
    // setTotal(total);

    setOpenScanner(false);
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          提交不良报告成功
        </Alert>
      </Snackbar>
      <Typography variant="h4" gutterBottom>
        不良报告
      </Typography>
      <form ref={formRef} onKeyDown={handleKeyDown} onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="date"
              label="日期"
              type="date"
              fullWidth
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="name"
              label="姓名"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              id="defectivePerson"
              label="不良人员"
              fullWidth
              value={defectivePerson}
              onChange={(e) => setDefectivePerson(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              id="customer"
              label="客户"
              fullWidth
              value={customer}
              onChange={(e) => setCustomer(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sx={{ position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                height: 56,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1,
                right: 8,
              }}
            >
              <IconButton onClick={() => setOpenScanner(true)}>
                <QrCodeIcon />
              </IconButton>
              <QrScannerModal
                open={openScanner}
                onScan={handleScan}
                onClose={() => setOpenScanner(false)}
              />
            </Box>
            <TextField
              required
              id="partNumber"
              label="图号"
              fullWidth
              value={partNumber}
              onChange={(e) => setPartNumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              required
              id="process"
              label="工序"
              fullWidth
              value={process}
              onChange={(e) => setProcess(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              id="machineModel"
              label="机器型号"
              fullWidth
              value={machineModel}
              onChange={(e) => setMachineModel(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              id="defectiveQuantity"
              label="不良数量"
              type="number"
              fullWidth
              value={defectiveQuantity}
              onChange={(e) => setDefectiveQuantity(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              id="total"
              label="总数"
              type="number"
              fullWidth
              value={total}
              onChange={(e) => setTotal(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="notes"
              label="备注"
              fullWidth
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <UploadPreviewImage onChange={(e) => setImage(e)} />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" fullWidth type="submit">
              提交
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}

export default ErrorReport;
