import React, { useState } from "react";
import { Container, Modal, IconButton } from "@mui/material";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { QrReader } from "@blackbox-vision/react-qr-reader";

const QrScannerModal = (props) => {
  const { onScan } = props;

  const [openScanner, setOpenScanner] = useState(false);

  const handleClose = () => {
    setOpenScanner(false);
  };

  const handleScan = (result, error) => {
    if (!!result) {
      onScan(result?.text);
      setOpenScanner(false);
    }
  };

  const isMobileDevice = () => {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("Mobile") !== -1
    );
  };

  if (!isMobileDevice()) return;

  return (
    <>
      <IconButton onClick={() => setOpenScanner(true)}>
        <QrCodeIcon />
      </IconButton>
      <Modal open={openScanner} onClose={handleClose} keepMounted>
        <Container
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <QrReader
            onResult={handleScan}
            constraints={{ facingMode: "environment", zoom: 3 }}
            style={{ width: "100%" }}
          />
        </Container>
      </Modal>
    </>
  );
};

export default QrScannerModal;
